import Box from "@gtg/components/box";
import Container from "@gtg/components/container";
import Icon from "@gtg/components/icon";
import NoWrap from "@gtg/components/noWrap";
import Seo from "@gtg/components/seo";
import Spinner from "@gtg/components/spinner";
import media from "@gtg/utils/media-queries";
import { Link } from "gatsby";
import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import styled, { css } from "styled-components";
import Footer from "../components/footer";
import GlobalWrapper from "../components/globalWrapper";
import DoctorImageUrl from "../images/Sergiu.jpg";
import HikerImageUrl from "../images/hiker.jpg";
import LensImageUrl from "../images/lens.jpg";
import LenzboxOpenImageUrl from "../images/lenzbox-open.jpg";
import LenzboxSideImageUrl from "../images/lenzbox-side.png";
import AutoCleanImageUrl from "../images/lenzbox.png";
import BooztUrl from "../images/logos/boozt.png";
import CollisionUrl from "../images/logos/collision.png";
import FrankfurtRheinMainUrl from "../images/logos/frankfurtrheinmain.png";
import HessenKapitalUrl from "../images/logos/hessenkapital.svg";
import RuhrSummitUrl from "../images/logos/ruhrsummit.svg";
import StationUrl from "../images/logos/station.png";
import WoehlkUrl from "../images/logos/woehlk.svg";
import ZuehlkeUrl from "../images/logos/zuehlke.png";
import ManRelaxedImageUrl from "../images/man-relaxed.png";
import QuotesImageUrl from "../images/quotes.svg";
import ScribbleImageUrl from "../images/scribble.svg";
import WomanContactLensImageUrl from "../images/woman-contact-lens.jpg";
import WomanLensImageUrl from "../images/woman-lens.jpg";
import { color, fontSize } from "../styles/theme";

const windowGlobal = typeof window !== "undefined" && window;

const CustomBox = ({ children }) => {
  return <Box py={[18, 14, 9, 6, 3, 2]}>{children}</Box>;
};

// general

const TextStyles = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  h2 {
    font-weight: normal;
    font-size: ${fontSize.f8};
    line-height: 1.15;
    margin-bottom: 2rem;
    ${media.lg`
      font-size: ${fontSize.f7};
    `}
    ${media.sm`
      font-size: ${fontSize.f6};
      margin-bottom: 1.25rem;
    `}
  }
  body,
  p {
    ${media.sm`
      margin: 0.75rem 0;
    `}
  }
`;

const CenterVertically = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const MobileOnly = styled.div`
  display: none;
  ${media.sm`
    display: block;
  `}
`;

// layout

const heroHeight = css`
  height: 100vh;
  min-height: 1000px;
  ${media.xl`
    min-height: 850px;
  `}
  ${media.lg`
    min-height: 750px;
  `}
  ${media.md`
    min-height: 0;
    height: auto;
  `}
  ${media.sm`
    min-height: 500px;
  `}
`;

const LightSection = styled.section`
  background: ${color.coal50};
  color: ${color.coal900};
`;

const DarkSection = styled.section`
  background: ${color.coal900};
  color: ${color.coal50};
`;

const AutoCleanSection = styled(LightSection)`
  background: linear-gradient(90deg, #ffdccd 0%, #ffefe2 100%);
`;

const LoveSection = styled(DarkSection)`
  background: #315858;
`;

const HeroSection = styled(LightSection)`
  ${heroHeight};
  max-width: 100%;
  overflow: hidden;
`;

const QualitySection = styled(LightSection)`
  position: relative;
  min-height: 1100px;
  color: ${color.coal900};
  ${media.xl`
    min-height: 1000px;
  `}
  ${media.lg`
    min-height: 700px;
  `}
  ${media.sm`
    min-height: 500px;
  `}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${media.sm`
    grid-template-columns: auto;
  `}
`;

// hero

const BlueBar = styled.div`
  background: ${color.brand500};
  height: 12px;
  width: 100%;
  position: relative;
  z-index: 1;
`;

const HeroMobile = styled.div`
  display: none;
  ${media.md`
    display: block;
  `}
`;

const HeroDesktop = styled.div`
  display: block;
  ${media.md`
    display: none;
  `}
`;

const HeroBox = styled(Box)`
  position: absolute;
  width: 100%;
  ${heroHeight};
`;

const HeroContainer = styled(Container)`
  ${heroHeight};
`;

const HeroRight = styled.div`
  display: flex;
  align-items: flex-end;
  ${heroHeight};
`;

const HeroText = styled.div`
  max-width: 700px;
  margin-top: 10vh;
  ${media.xl`
    max-width: 520px;
  `}
  ${media.lg`
    max-width: 380px;
  `}
  ${media.md`
    max-width: 100%;
    margin-top: -1rem;
  `}
`;

const H1Bar = styled.div`
  width: 70px;
  height: 7px;
  background: ${color.coal900};
  margin-bottom: 2rem;
  ${media.xl`
    width: 60px;
    height: 6px;
  `}
  ${media.md`
    margin-bottom: 1rem;
  `}
  ${media.sm`
    width: 45px;
    height: 5px;
  `}
`;

const H1 = styled.h1`
  position: relative;
  margin-bottom: 8rem;
  font-size: ${fontSize.f11};
  line-height: 1.2;
  ${media.xl`
    font-size: ${fontSize.f10};
    margin-bottom: 7rem;
  `}
  ${media.lg`
    font-size: ${fontSize.f8};
    margin-bottom: 4rem;
  `}
  ${media.md`
    margin-bottom: 2rem;
    max-width: 380px;
  `}
  ${media.sm`
    font-size: ${fontSize.f7};
    margin-bottom: 1rem;
  `}
`;

const Red = styled.span`
  color: #ff8078;
`;

const Scribble = styled.div`
  background: url(${ScribbleImageUrl}) no-repeat;
  background-size: contain;
  width: 236px;
  height: 26px;
  position: absolute;
  bottom: -40px;
  left: 200px;
  ${media.xl`
    left: 130px;
    width: 180px;
  `}
  ${media.lg`
    bottom: -35px;
    width: 120px;
    left: 100px;
  `}
  ${media.md`
    display: none;
  `}
`;

const Teaser = styled.div`
  font-size: ${fontSize.f5};
  margin-bottom: 2rem;
  ${media.md`
    font-size: ${fontSize.f4};
    margin-bottom: 1rem;
  `}
`;

const Slogan = styled.span`
  font-size: ${fontSize.f10};
  line-height: 1.1;
  ${media.xl`
    font-size: ${fontSize.f9};
  `}
  ${media.lg`
    font-size: ${fontSize.f8};
  `}
`;

const SoonAvailable = styled.div`
  margin-bottom: 6rem;
  ${media.lg`
    margin-bottom: 3rem;
  `}
  ${media.sm`
    margin-bottom: 2rem;
  `}
`;

const LenzboxContainer = styled(Container)`
  position: absolute;
  pointer-events: none;
  overflow: hidden;
  max-width: 100vw;
  ${heroHeight};
`;

const LenzboxSideImageContainer = styled.div`
  position: relative;
  top: 15vh;
  left: 45%;
  height: 50vh;
  pointer-events: none;
  ${media.xl`
    left: 48%;
  `}
  ${media.lg`
    left: 52%;
  `}
  ${media.md`
    left: 50%;
    top: 0;
    height: 50%;
  `}
  ${media.sm`
    position: static;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 200px;
    margin-bottom: 2rem;
    // added margin-left to avoid that Lenzbox-logo and Lenzbox-image overlap
    margin-left: 3rem;
  `}
  ${media.xs`
    margin-bottom: 1rem;
    // added margin-left to avoid that Lenzbox-logo and Lenzbox-image overlap
    margin-left: 3rem;
    height: 150px;
  `}
`;

const LenzboxSideImage = styled.div`
  width: 50%;
  height: 100%;
  background: url(${LenzboxSideImageUrl}) no-repeat left center;
  background-size: contain;
  min-height: 500px;
  ${media.xl`
    min-height: 430px;
  `}
  ${media.md`
    min-height: 360px;
  `}
  ${media.sm`
    min-height: 0;
    background-position: 55% center;
  `}
`;

// form

const InputAndButton = styled.div`
  display: flex;
  input {
    width: 15rem;
    border: 0;
    box-shadow: 0 0 0 1px ${color.coal300};
    margin: 0 1rem 0 0;
    :focus {
      box-shadow: 0 0 0 2px ${color.brand500};
    }
    ::placeholder {
      color: ${color.coal400};
      opacity: 1;
    }
  }
  button {
    white-space: nowrap;
    box-shadow: 0 0 0 2px ${color.brand500};
    margin: 0;
    transform: translate(0, -1px);
    :hover {
      box-shadow: 0 0 0 2px ${color.brand400};
    }
    :focus {
      box-shadow: 0 0 0 2px ${color.brand300};
    }
    :disabled {
      background: ${color.brand400};
      cursor: default;
      box-shadow: 0 0 0 2px ${color.brand400};
    }
  }
  ${media.lg`
    display: block;
    input {
      width: 10rem;
    }
    button {
      padding: 1rem;
    }
  `}
  ${media.md`
    display: flex;
    input {
      width: auto;
      margin: 0 1rem 0 0;
    }
    button {
      width: auto;
    }
  `}
  ${media.sm`
    display: block;
    input {
      width: 100%;
      margin-bottom: 1rem;
    }
    button {
      width: 100%;
    }
  `}
`;

const SuccessMessage = styled.div`
  margin-left: 1rem;
`;

const SuccessMessageContainer = styled.div`
  background: ${color.green50};
  padding: 1rem;
  border: 1px solid ${color.green200};
  border-radius: 4px;
  color: ${color.green700};
  display: inline-flex;
  align-items: center;
  max-width: 36rem;
`;

const PrivacyDisclaimer = styled.div`
  margin-top: 1rem;
  font-size: ${fontSize.f2};
  color: ${color.coal500};
  a {
    color: ${color.coal500};
    :visited {
      color: ${color.coal500};
    }
    :focus {
      color: ${color.coal500};
    }
    :hover {
      color: ${color.coal500};
    }
  }
`;

const InputContainer = styled.div``;

// auto clean section

const AutoCleanGrid = styled.div`
  display: grid;
  grid-template-columns: 10fr 7fr;
  ${media.md`
    grid-template-columns: 1fr 1fr;
  `}
  ${media.sm`
    display: flex;
    flex-direction: column;
  `}
`;

const AutoCleanImage = styled.div`
  width: 100%;
  background: url(${AutoCleanImageUrl}) no-repeat center 0%;
  background-size: cover;
  height: 100%;
  ${media.sm`
    min-height: 300px;
  `}
`;

// eye love section

const LoveGrid = styled.div`
  display: grid;
  grid-template-columns: 10fr 7fr;
  ${media.md`
    grid-template-columns: 1fr 1fr;
  `}
  ${media.sm`
    display: flex;
    flex-direction: column-reverse;
  `}
`;

const LoveText = styled.div`
  max-width: 450px;
`;

const WomanContactLensImage = styled.div`
  width: 100%;
  background: url(${WomanContactLensImageUrl}) no-repeat center center;
  background-size: cover;
  ${media.sm`
    min-height: 300px;
  `}
`;

// hiker section

const HikerGrid = styled.div`
  display: grid;
  grid-template-columns: 10fr 7fr;
  ${media.md`
    grid-template-columns: 1fr 1fr;
  `}
  ${media.sm`
    grid-template-columns: auto;
  `}
`;

const HikerImage = styled.div`
  width: 100%;
  background: url(${HikerImageUrl}) no-repeat right center;
  height: 100%;
  background-size: cover;
  ${media.sm`
    min-height: 350px;
  `}
`;

// relax section

const RelaxedGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${media.sm`
    display: flex;
    flex-direction: column-reverse;
  `}
`;

const ManRelaxedImage = styled.div`
  width: 100%;
  background: url(${ManRelaxedImageUrl}) no-repeat center center;
  height: 100%;
  background-size: contain;
  ${media.sm`
    min-height: 200px;
    box-shadow: none;
    border-radius: 0;
    margin-top: 3rem;
  `}
`;

// quality section

const LenzboxOpenImageContainer = styled(Container)`
  position: absolute;
  top: 3rem;
  ${media.xl`
    top: 0;
  `}
`;

const LenzboxOpenImage = styled.div`
  background: url(${LenzboxOpenImageUrl}) no-repeat 23% center;
  background-size: cover;
  width: 100%;
  min-height: 1000px;
  ${media.lg`
    min-height: 700px;
  `}
  ${media.sm`
    min-height: 300px;
    transform: translateY(25px);
// added margin-bottom to avoid image overlapping text "Qualität"
    margin-bottom: 1rem;
  `}
`;

const QualityTextContainer = styled(Container)`
  position: relative;
`;

const QualityDesktop = styled.div`
  display: block;
  ${media.sm`
    display: none;
  `}
`;

// doctor section

const DoctorGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${media.md`
    grid-template-columns: auto;
  `}
`;

const QuotationBox = styled(Box)`
  background: ${color.coal800};
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 4rem;
  border-left: 12px solid ${color.brand500};
  border-radius: 4px;
  ${media.lg`
    grid-gap: 3rem;
  `}
  ${media.sm`
    grid-gap: 1rem;
    grid-template-columns: auto;
  `}
  ${media.md`
    border-radius: 0;
  `}
`;

const QuotationImage = styled.div`
  background: url(${DoctorImageUrl}) no-repeat center 14%;
  background-size: cover;
  width: 100%;
  ${media.sm`
    height: 100px;
    width: 100px;
    border-radius: 50%;
  `}
`;

const QuotationText = styled.div`
  p {
    margin-top: 0;
  }
`;

const DoctorText = styled.div`
  max-width: 500px;
`;

const QuotesImage = styled.div`
  background: url(${QuotesImageUrl}) no-repeat center center;
  background-size: contain;
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
  ${media.md`
    width: 40px;
    height: 40px;
  `}
  ${media.sm`
    width: 30px;
    height: 30px;
    margin-bottom: 0.5rem;
  `}
`;

const Role = styled.span`
  opacity: 0.5;
  font-size: ${fontSize.f2};
`;

// environment section

const LensImage = styled.div`
  background: url(${LensImageUrl}) no-repeat center bottom;
  background-size: cover;
  width: 100%;
  height: 100%;
  ${media.sm`
    min-height: 300px;
  `}
`;

const Footnote = styled.div`
  font-size: ${fontSize.f2};
  color: ${color.coal400};
  margin-top: 2rem;
  ${media.sm`
    margin-top: 1rem;
  `}
`;

// CTA section

const CTABoxContainer = styled.div`
  background: ${color.coal800};
  display: grid;
  grid-template-columns: 4fr 3fr;
  grid-gap: 0rem;
  border-radius: 4px;
  overflow: hidden;
  ${media.xl`
    grid-template-columns: 2fr 1fr;
  `}
  ${media.lg`
    grid-template-columns: 4fr 1fr;
  `}
  ${media.md`
    grid-template-columns: auto;
    grid-gap: 0rem;
  `}
  ${media.sm`
    grid-template-columns: auto;
    grid-gap: 0;
    button {
      width: 100%;
      margin-bottom: 0;
    }
  `}
`;

const WomanLensImage = styled.div`
  background: url(${WomanLensImageUrl}) no-repeat center 20%;
  background-size: cover;
  width: 100%;
  height: 100%;
  ${media.md`
    min-height: 200px;
  `}
`;

// Logo Section

const LogosSection = styled(LightSection)`
  background: ${color.coal100};
  h2 {
    font-size: ${fontSize.f4};
    color: ${color.coal500};
    font-weight: bold;
    margin-bottom: 1rem;
  }
`;

const LogosWrapper = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 0;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  align-items: center;
  justify-items: center;
  background: #f3e8d6;
  padding: 1rem 2rem;
  border-radius: 4px;
  ${media.lg`
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-column-gap: 1rem;
  `}
  ${media.xs`
    grid-gap: 8px;
  `}
`;

const LogoImg = styled.img`
  max-width: 140px;
  mix-blend-mode: darken;
  ${media.lg`
    max-width: 100px;
  `}
  ${media.xs`
    max-width: 100px;
  `}
`;

const LogosGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  ${media.lg`
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  `}
`;

//Logo

const LogoContainer = styled(Container)`
  height: 80px;
`;

const LogoLink = styled.a`
  z-index: 2;
  font-size: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

const LogoImg2 = styled.img`
  object-fit: contain;
  object-position: left;
  max-height: 48px;
  z-index: 2;
  ${media.xs`
    max-width: 154px;
    max-height: 30px;
  `}
`;

const CustomFade = ({ children }) => {
  return (
    <Fade
      triggerOnce={true}
      cascade={false}
      delay={50}
      duration={1500}
      fraction={1}
    >
      {children}
    </Fade>
  );
};

const Form = (props) => {
  return (
    <InputContainer>
      {!props.formHasBeenSubmitted && (
        <>
          <InputAndButton>
            <form
              action="https://lenzbox.de/wp-admin/?fluentcrm=1&route=contact&hash=3f9d0b3e-3101-4748-850b-2e67d29aa4b9"
              method="POST"
              onSubmit={props.submitForm}
            >
              <input
                type="email"
                name="email"
                required
                placeholder="E-Mail"
              ></input>
              <button type="submit" disabled={props.formSubmissionPending}>
                {props.formSubmissionPending && <Spinner size={12} />}Infos
                erhalten
              </button>
              <PrivacyDisclaimer hero={props.hero}>
                Der Schutz deiner Daten ist uns wichtig:{" "}
                <Link to="/datenschutz">Datenschutzerklärung</Link>
              </PrivacyDisclaimer>
            </form>
          </InputAndButton>
        </>
      )}
      {props.formHasBeenSubmitted && (
        <SuccessMessageContainer>
          <Icon glyph="checkmark" fill={color.green600} />
          <SuccessMessage>
            <div>
              <div>
                <em>Vielen Dank.</em>
              </div>
              Klicke auf den Link in der Bestätigungs-E-Mail, um die Anmeldung
              abzuschließen.
            </div>
          </SuccessMessage>
        </SuccessMessageContainer>
      )}
    </InputContainer>
  );
};

const HeroContent = (props) => {
  return (
    <HeroText>
      <CustomFade>
        <H1Bar />
        <H1>
          Das neue <Red>Zuhause</Red> für deine Kontaktlinsen.
          <br />
          <Scribble />
        </H1>
        <h2>
          <Red>
            Jetzt Deinen{" "}
            <a
              href="https://buy.stripe.com/test_6oEfZM6aR3JF8r63cf"
              target="_blank"
              rel="noreferrer noopener"
            >
              Wartelisteplatz
            </a>{" "}
            sichern!
          </Red>
        </h2>
        <Teaser>
          Wie?{" "}
          <a
            href="https://buy.stripe.com/test_6oEfZM6aR3JF8r63cf"
            target="_blank"
            rel="noreferrer noopener"
          >
            Hier einfach 5 Euro anzahlen
          </a>{" "}
          und <Red>20 Euro Rabatt auf Deine Lenzbox erhalten</Red>.
          <br />
        </Teaser>
      </CustomFade>
      Dann bekommst Du früher als andere die weltweit erste Kontaktlinsenbox mit
      automatischer Pflegefunktion und trockener Entnahme.
      <br />
      <br />
      <em>Kein Risiko!</em> Falls Du es Dir später anders überlegst, genügt eine
      kurze Nachricht und Du erhälst die volle Anzahlung zurück.
      <br />
      <br />
      <a
        href="https://buy.stripe.com/test_6oEfZM6aR3JF8r63cf"
        target="_blank"
        rel="noreferrer noopener"
      >
        <button> Rabatt sichern</button>
      </a>
    </HeroText>
  );
};

const IndexPage = () => {
  const [formSubmissionPending, setFormSubmissionPending] = useState(false);
  const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);
  const submitForm = (e) => {
    e.preventDefault();
    setFormSubmissionPending(true);
    const form = e.target;
    const data = new windowGlobal.FormData(form);
    const xhr = new windowGlobal.XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== windowGlobal.XMLHttpRequest.DONE) return;
      // Pending never stopped, hence status verification replaced by >=0 instead of ==200 (Peter, 09.09.2022)
      // if (xhr.status === 200) {
      if (xhr.status >= 0) {
        form.reset();
        setFormHasBeenSubmitted(true);
        setFormSubmissionPending(false);
      }
    };
    xhr.send(data);
  };

  return (
    <GlobalWrapper>
      <Seo />
      <BlueBar />
      <Box py={0}>
        <LogoContainer wMax="xl">
          <LogoLink href="/" aria-label="Go to Homepage">
            <LogoImg2
              src="/logo-dark.svg"
              width="160"
              height="148"
              alt="logo"
            />
          </LogoLink>
        </LogoContainer>
      </Box>
      <TextStyles>
        <HeroSection>
          <HeroDesktop>
            <LenzboxContainer wMax="xl">
              <LenzboxSideImageContainer>
                <LenzboxSideImage />
              </LenzboxSideImageContainer>
            </LenzboxContainer>
            <HeroBox pt={6}>
              <Container wMax="xl">
                <HeroContent
                  hero
                  submitForm={submitForm}
                  formSubmissionPending={formSubmissionPending}
                  formHasBeenSubmitted={formHasBeenSubmitted}
                />
              </Container>
            </HeroBox>
            <HeroContainer
              wMax="xl"
              background={color.coal50}
              splitBackground={color.coal900}
              splitColor={color.coal50}
              contentRight={
                <HeroRight>
                  <Box>
                    <Slogan>
                      Your eyes
                      <br />
                      will love you.
                    </Slogan>
                  </Box>
                </HeroRight>
              }
            />
          </HeroDesktop>
          <HeroMobile>
            <Box>
              <LenzboxSideImageContainer>
                <LenzboxSideImage />
              </LenzboxSideImageContainer>
              <HeroContent
                submitForm={submitForm}
                formSubmissionPending={formSubmissionPending}
                formHasBeenSubmitted={formHasBeenSubmitted}
              />
            </Box>
          </HeroMobile>
        </HeroSection>

        <LightSection>
          <Container wMax="xl">
            <HikerGrid>
              <HikerImage />
              <CenterVertically>
                <CustomBox>
                  <CustomFade>
                    <div>
                      <h2>
                        Für zuhause.
                        <br />
                        Für unterwegs.
                        <br />
                        Für jeden Tag.
                      </h2>
                      <p>
                        Einfach und bequem: Linsen einlegen, Knopf drücken,
                        fertig. Kein Hantieren mit Flaschen, Döschen und Linsen.
                      </p>
                      <p>LENZBOX: Saubere Linsen statt nasser Finger.</p>
                      <br />
                      <a
                        href="https://buy.stripe.com/test_6oEfZM6aR3JF8r63cf"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <button> LENZBOX reservieren</button>
                      </a>
                    </div>
                  </CustomFade>
                </CustomBox>
              </CenterVertically>
            </HikerGrid>
          </Container>
        </LightSection>

        <LoveSection>
          <Container wMax="xl">
            <LoveGrid>
              <CenterVertically>
                <CustomBox>
                  <CustomFade>
                    <LoveText>
                      <h2>
                        Deine Augen werden
                        <br />
                        dich lieben.
                      </h2>
                      <p>
                        Die einzigartige Funktionalität schont deine Linsen. Das
                        zeitlose, durchdachte Design schont deine Nerven.
                      </p>
                      <p>
                        <a
                          href="https://buy.stripe.com/test_6oEfZM6aR3JF8r63cf"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Hier
                        </a>{" "}
                        einen der fortschrittlichsten Kontaktlinsenbehälter der
                        Welt vorbestellen.
                      </p>
                      <br />
                      <a
                        href="https://buy.stripe.com/test_6oEfZM6aR3JF8r63cf"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <button> Jetzt vorbestellen</button>
                      </a>
                    </LoveText>
                  </CustomFade>
                </CustomBox>
              </CenterVertically>
              <WomanContactLensImage />
            </LoveGrid>
          </Container>
        </LoveSection>
        <QualitySection>
<QualityDesktop>
            <LenzboxOpenImageContainer wMax="xl">
              <LenzboxOpenImage />
            </LenzboxOpenImageContainer>
            <QualityTextContainer
              wMax="xl"
              splitBackground
              splitRatio={"1fr 1fr"}
              contentRight={
                <CustomBox>
                  <CustomFade>
                    <div>
                      <h2>
                        Qualität.
                        <br />
                        Made in Germany.
                      </h2>
                      <Teaser>
                        Jetzt Deinen{" "}
                        <a
                          href="https://buy.stripe.com/test_6oEfZM6aR3JF8r63cf"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Wartelisteplatz
                        </a>{" "}
                        sichern und{" "}
                        <Red>20 Euro Rabatt auf Deine Lenzbox erhalten</Red>.
                        <br />
                      </Teaser>
                      Dann gehörst Du weltweit zu den Ersten, die Ihre
                      Kontaktlinsen einfach und bequem pflegen.
                      <br />
                      <br />
                      <a
                        href="https://buy.stripe.com/test_6oEfZM6aR3JF8r63cf"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <button> Erster sein</button>
                      </a>
                    </div>
                  </CustomFade>
                </CustomBox>
              }
            />
            </QualityDesktop>
          <MobileOnly>
            <LenzboxOpenImage />
            <Box>
              <h2>
                Qualität.
                <br />
                Made in Germany.
              </h2>

              <Teaser>
                        Jetzt Deinen{" "}
                        <a
                          href="https://buy.stripe.com/test_6oEfZM6aR3JF8r63cf"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Wartelisteplatz
                        </a>{" "}
                        sichern und{" "}
                        <Red>20 Euro Rabatt auf Deine Lenzbox erhalten</Red>.
                        <br />
                      </Teaser>
                      Dann gehörst Du weltweit zu den Ersten, die Ihre
                      Kontaktlinsen einfach und bequem pflegen.
                      <br />
                      <br />
                      <a
                        href="https://buy.stripe.com/test_6oEfZM6aR3JF8r63cf"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <button> Erster sein</button>
                      </a>


            </Box>
          </MobileOnly>

        </QualitySection>
        <LogosSection>
          <Box pyMax="lg">
            <Container wMax="xl">
              <LogosGrid>
                <div>
                  <h2>Unsere Partner</h2>
                  <LogosWrapper>
                    <LogoImg src={HessenKapitalUrl} alt="Hessen Kapital Logo" />
                    <LogoImg
                      src={FrankfurtRheinMainUrl}
                      alt="FrankfurtRheinMain Logo"
                    />
                    <LogoImg src={ZuehlkeUrl} alt="Zuehlke Logo" />
                    <LogoImg src={WoehlkUrl} alt="Woehlk Logo" />
                  </LogosWrapper>
                </div>
                <div>
                  <h2>Bekannt durch</h2>
                  <LogosWrapper>
                    <LogoImg src={CollisionUrl} alt="Collision Logo" />
                    <LogoImg src={RuhrSummitUrl} alt="RuhrSummit Logo" />
                    <LogoImg src={BooztUrl} alt="Boozt Logo" />
                    <LogoImg src={StationUrl} alt="Station Logo" />
                  </LogosWrapper>
                </div>
              </LogosGrid>
            </Container>
          </Box>
        </LogosSection>
        <DarkSection>
          <Box pyMax="lg" pb={0}>
            <Container wMax="xl">
              <CTABoxContainer>
                <Box pMax="xl">
                  <div>
                    <h2>Lass dich benachrichtigen.</h2>
                    <p>
                      Die Lenzbox wird Ende 2022 verfügbar sein.
                      <br />
                      Melde dich jetzt zum Newsletter an, um alle Neuigkeiten sofort zu erfahren.
                    </p>
                    <br />
                    <br />
                    <Form
                      submitForm={submitForm}
                      formSubmissionPending={formSubmissionPending}
                      formHasBeenSubmitted={formHasBeenSubmitted}
                    />
                  </div>
                </Box>
                <WomanLensImage />
              </CTABoxContainer>
            </Container>
          </Box>
        </DarkSection>
        <Footer wMax="xl" />
      </TextStyles>
    </GlobalWrapper>
  );
};

export default IndexPage;
